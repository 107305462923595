import { Box } from "grommet/es6";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ImpressumPage } from "./pages/ImpressumPage";
import { NewSessionPage } from "./pages/NewSessionPage";
import PokerPage from "./pages/PokerPage";
import { StartPage } from "./pages/StartPage";

const App = () => (
  <Box>
    <Routes>
      <Route path={"/"} index element={<StartPage />} />
      <Route path={"/new"} element={<NewSessionPage />} />
      <Route path={"/poker/:sessionId"} element={<PokerPage />} />
      <Route path={"/impressum"} element={<ImpressumPage />} />
    </Routes>
  </Box>
);

export default App;
