import { Grommet } from "grommet/es6";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { annapokerTheme } from "./models/theme";
import "./style.css";

ReactDOM.render(
  <BrowserRouter>
    <Grommet theme={annapokerTheme}>
      <App />
    </Grommet>
  </BrowserRouter>,
  document.getElementById("root")
);
